import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import iconValues from './icons';
import '@mdi/font/css/materialdesignicons.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#4E4087',
        secondary: '#DDDCFF',
        accent: '#232451',
        error: '#FF6262',
        text: '#675D8B',
        border: '#CFCDF6'
      }
    }
  },
  icons: {
    values: iconValues,
    iconfont: 'mdi'
  }, 
});
