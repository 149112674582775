export default {
  state: {
    typeEdit: 'list',
    hdriEdit: null,
    hdris: null,
    initialItem: 0,
    getHdriTrigger: 0,
    currentWidget: {},
    triggerTakeOff: 0,
    errorSize: false,
    ZVClass: {}
  },
  mutations: {
    setValueModule (state, keyValue) {
      // console.log('set value module render')
      if (keyValue.key in state) {
        state[keyValue.key] = keyValue.value
      }
    },
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    }
  },
  actions: {
    SET_VALUE ({ commit }, keyValue) {
      commit('setValue', keyValue)
    },
    SET_VALUE_MODULE ({commit}, keyValue) {
      commit('setValueModule', keyValue)
    },
  }
}
